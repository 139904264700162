import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/runner/work/starswap-front-home/starswap-front-home/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Starswap V1`}</strong>{` was the proof-of-concept for a new type of decentralized marketplace.`}</p>
    <p>{`As a venue for pooled, automated liquidity provision on Ethereum, the Starswap protocol (Starswap) functions without upkeep, providing an unstoppable platform for ERC20 token conversion. `}<strong parentName="p">{`Starswap V1 will continue to work for as long as Ethereum exists`}</strong>{`, and so far, it has worked very nicely for a wide variety of use cases.`}</p>
    <p><figure parentName="p" {...{
        "className": "gatsby-resp-image-figure",
        "style": {}
      }}>{`
    `}<span parentName="figure" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1200px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/928c430d3a3cd382fb699c009c663723/f349c/v1_liquidity.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsTAAALEwEAmpwYAAABXUlEQVQoz52QbUvCYBSG99frg2VpaQlpVJSBimnTOecU1DLcypelWaRRpvWhVNB8rLX5PDsLXW9ERHpzPt4X1zmHGg6HhBB9igBQU8MDVaFUVcUYT0qqeFio5KY0y7KcEo4mNgOArusd1JWE2Aj+vxn0Efmq4XCcK3HZCcwagA6AgaTyos/vrhVZCmNMCIE/o43HWFi6qvoiwYzb7/PsvMM/rvpsw9hmYN0BSpdPg0I6RdMLM+t2m2P07e9rw0fV+Iym67JGGgMkth75ssSJyQM6uG1xz2+ygaT0i1kh+L7fv2h3pGYzf1nNFHPxXDYhHWdoX3LR7DItL3mS3hxK1+Qv2BCqihITs2v0/kZgb9fhDMzZWLOdta7yFqvdtDLrYp2JuvcEbR22+bMeBQCYEJUQhFCv91S/bXAcz0U5JsQwIZZhoqFwlInEAsGQULk7f4By86XUeC7coOuW8gZgI9GQ/dodPgAAAABJRU5ErkJggg==')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Starswap V1 Liquidity Growth",
              "title": "Starswap V1 Liquidity Growth",
              "src": "/static/928c430d3a3cd382fb699c009c663723/c1b63/v1_liquidity.png",
              "srcSet": ["/static/928c430d3a3cd382fb699c009c663723/5a46d/v1_liquidity.png 300w", "/static/928c430d3a3cd382fb699c009c663723/0a47e/v1_liquidity.png 600w", "/static/928c430d3a3cd382fb699c009c663723/c1b63/v1_liquidity.png 1200w", "/static/928c430d3a3cd382fb699c009c663723/d61c2/v1_liquidity.png 1800w", "/static/928c430d3a3cd382fb699c009c663723/f349c/v1_liquidity.png 2002w"],
              "sizes": "(max-width: 1200px) 100vw, 1200px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
  `}</a>{`
    `}</span>{`
    `}<figcaption parentName="figure" {...{
          "className": "gatsby-resp-image-figcaption"
        }}>{`Starswap V1 Liquidity Growth`}</figcaption>{`
  `}</figure></p>
    <p>{`However, pooled automated liquidity remains nascent technology, and `}<strong parentName="p">{`we have only just begun to realize its potential`}</strong>{`. For this reason, last year `}<a parentName="p" {...{
        "href": "https://finance.yahoo.com/news/paradigm-backs-decentralized-exchange-protocol-184824051.html"
      }}>{`we raised a seed round`}</a>{` and formed a dedicated team to research and develop Starswap alongside the broader Ethereum community.`}</p>
    <p><strong parentName="p">{`Starswap V2`}</strong>{` is our second iteration of Starswap and includes many new features and improvements. This article will serve as a high-level overview of these changes including:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#erc20--erc20-pairs"
        }}>{`ERC20 / ERC20 Pairs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#price-oracles"
        }}>{`Price Oracles`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#flash-swaps"
        }}>{`Flash Swaps`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#corehelper-architecture"
        }}>{`Core/Helper Architecture`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#technical-improvements"
        }}>{`Technical Improvements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#path-to-sustainability"
        }}>{`Path to Sustainability`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#testnet-and-launch-details"
        }}>{`Testnet and Launch Details`}</a></li>
    </ul>
    <p>{`For full details check out the:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-core/"
        }}>{`Core smart contracts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-periphery"
        }}>{`Periphery smart contracts`}</a></li>
      <li parentName="ul"><a href='/whitepaper.pdf' target='_blank' rel='noopener noreferrer'>Starswap V2 Technical Whitepaper</a></li>
      <li parentName="ul"><a href='https://docs.starswap.xyz'>Starswap V2 Documentation</a> (in progress!)</li>
    </ul>
    <h2 {...{
      "id": "erc20--erc20-pairs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#erc20--erc20-pairs",
        "aria-label": "erc20  erc20 pairs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ERC20 / ERC20 Pairs`}</h2>
    <p>{`In Starswap V2, `}<strong parentName="p">{`any ERC20 token can be pooled directly with any other ERC20 token`}</strong>{`. Wrapped Ether (WETH) is used instead of native ETH in the core contracts, although end users can still use ETH through helper contracts.`}</p>
    <p>{`In Starswap V1, all liquidity pools are between ETH and a single ERC20 token. Having a constant numeraire provides a nice UX advantage — users can swap any ERC20 for any other ERC20 by routing through ETH. Since ETH is the most liquid Ethereum-based asset, and does not introduce any new platform risk, it was the best choice for Starswap V1.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/4289820d83c2d5c006bed121ec281580/5c263/v1_DAI_USDC_swap.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "80%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAACE4AAAhOAFFljFgAAABnUlEQVQ4y51Ua2+bQBDM//9f/VTJbqoY2THUSl05hRgbjuNxd+xMBQb8AKKoK1bHLcvczuzCE4CaF0O3UkQIYORtEoa0W+uD8kSyFgvmOodSis45ztkM2Bgw/Bvx+cczPM/j4XDgdrul7/vt/X6/ZxRFw0EzlaNbO8Aw5GazQRAE3O12XK1WXK/XLdhyueRisaAx5muAUxp+mfJV13vKzV4EELmeKuIoNqM4TRHbv9tSPyeKp3PKJM1orRtTBoRwOVAXbF0qwhxZn76z/vhGKQ+EGLIuaE3O4Ncf/nx55dvvdxpjHymzZnPB9GrcUUPvXawZKaU0k0RR64J1LeMKtc4ZxycMot8CA4O8txKqLG9kmtaw0SQMj7gK//lQN1WGUdzr1wLeVViUFTNdoic5NcCPsese48FuZCg6CaVLzCthlDoelaMu64Fy04RzmvEYJ5cuOzfRFIDWAYVBA8zKgh/KMQhN6+9n28ZKC8aJprcO+OL5fPXfWBTV/w725XFbYaKYplnrzk3OIQZh533+5zDq8kNwsiG3nf7sW/4H6/fobiYEDMUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v1 DAI USDC swap",
            "title": "v1 DAI USDC swap",
            "src": "/static/4289820d83c2d5c006bed121ec281580/c1b63/v1_DAI_USDC_swap.png",
            "srcSet": ["/static/4289820d83c2d5c006bed121ec281580/5a46d/v1_DAI_USDC_swap.png 300w", "/static/4289820d83c2d5c006bed121ec281580/0a47e/v1_DAI_USDC_swap.png 600w", "/static/4289820d83c2d5c006bed121ec281580/c1b63/v1_DAI_USDC_swap.png 1200w", "/static/4289820d83c2d5c006bed121ec281580/5c263/v1_DAI_USDC_swap.png 1636w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`However, the introduction of ERC20 token/ERC20 token pools in Starswap V2 can be `}<strong parentName="p">{`useful for liquidity providers, who can maintain more diverse ERC20 token denominated positions`}</strong>{`, without mandatory exposure to ETH. One example would be a DAI/USDC pair, which should theoretically have little volatility for liquidity providers, but is useful for trading.`}</p>
    <p>{`Having direct ERC20/ERC20 pairs can also improve prices because routing through ETH for a swap between two other assets (say, DAI/USDC) involves paying fees and slippage on two separate pairs instead of one.`}</p>
    <p>{`If two ERC20 tokens are not paired directly, and do not have a common pair between them, they can still be swapped as long as a path between them exists. Router contracts can be used to optimize between direct and multi-step swaps.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c89a7f55518c0d6ca47d4b4813722c01/ec9b3/v2_swaps.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "126.33333333333334%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAZCAYAAAAxFw7TAAAACXBIWXMAACE4AAAhOAFFljFgAAACrElEQVQ4y5VVi3KjMAzM/39hc702pIFAeL+MwZa1NzKBkDaT5jKjwTiwllarZQeAiBymaWT88mP+9RHswKD92x8EQcBBECCKIpzPZyRJ4q9yL2siegl0x8xU1RWUUpznOeq6RpqmPsqyhOxlWQbn3OuA1lpYa3l56VnJz2IFjOOzZMRhGELr4WW+Hh3oAcdxkpJ4nCZ0nfLhHKHreiRJijTLIf+Rc7BDDGu0X+txRJZXKMp65XjnnKOiqHzJSg3Ii3p+wBGqqsHnIcTn4QQ1DCCyOOc9ej35A/teITpfEMUprKUNhzP6SzU6/qVk0eHtnu+4Wwm/nrbF4mccSneFQ7nKpjEGy9qHs3MwgUmDnQGY4MhAKY1eachwzMIG6HQ6IQxDFiEfj0cvaK319VyagwXQgaccTKIEB2NGfJ1iBMcIxtgbh1d0n6GQu2TWqwFVWaBtO1jr1lJ5U7Z011h706F0OU1FFiPnReW7VtctBq3x9+OIt/0H9u8H1E23EOWhFoBxMtB6vOdQOONvSnaeO4axDCJeR8859v8tAKLhQY+3DB91eYtNjjHZOSwx8tai6gnCUqdGBF9nfAYh1KBvHF5B7mRzW3+f1xuLkvWS4Top2wy/+96dJv9nlgVddHi9bjISuY1g03jdDYPCJS3RtD2myfiGFGWDNCsxbWUTx7EYKYuZih/ezQNfAeG8WXyFCcqq8bqTcpNLjjBMPPgKqMdJpoO7XqGV082Eqm69U5dV/bxcGW5zM9/ZbcparIirqvUlTMYgSTLs3wOcwviqvQcGK3vKgKth1ejK4SPeRRqSwDOHZnJgQ/PLW7fZymYbnSZcagtDQFbUOBzlI5YhGxtkpkEUpTgEEYSuHzr8KZn7MRPxyghKlw1ZWEeec9m7a8ozHS5SfOUbI035B/VAqW3LbGmcAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v2 swaps",
            "title": "v2 swaps",
            "src": "/static/c89a7f55518c0d6ca47d4b4813722c01/c1b63/v2_swaps.png",
            "srcSet": ["/static/c89a7f55518c0d6ca47d4b4813722c01/5a46d/v2_swaps.png 300w", "/static/c89a7f55518c0d6ca47d4b4813722c01/0a47e/v2_swaps.png 600w", "/static/c89a7f55518c0d6ca47d4b4813722c01/c1b63/v2_swaps.png 1200w", "/static/c89a7f55518c0d6ca47d4b4813722c01/d61c2/v2_swaps.png 1800w", "/static/c89a7f55518c0d6ca47d4b4813722c01/97a96/v2_swaps.png 2400w", "/static/c89a7f55518c0d6ca47d4b4813722c01/ec9b3/v2_swaps.png 2539w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`We still anticipate ETH pairs being very popular, but expect to see growth in other types of pairs over time.`}</p>
    <h2 {...{
      "id": "price-oracles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#price-oracles",
        "aria-label": "price oracles permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Price Oracles`}</h2>
    <p><strong parentName="p">{`Starswap V2 implements new functionality that enables highly decentralized and manipulation-resistant on-chain price feeds.`}</strong>{` This is achieved by measuring prices when they are expensive to manipulate, and cleverly accumulating historical data. This allows external smart contracts to create gas-efficient, time-weighted averages of Starswap prices across `}<strong parentName="p">{`any`}</strong>{` time interval.`}</p>
    <p><strong parentName="p">{`On-chain price feeds are a critical component for many decentralized financial applications`}</strong>{` including those similar to derivatives, lending, margin trading, prediction markets and more. Despite `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/1911.03380"
      }}>{`closely tracking the real-world price`}</a>{` most of the time, Starswap V1 cannot be used safely as a price oracle because the price can move significantly in a short period of time.`}</p>
    <p>{`Starswap V2 includes a number of improvements for price feeds built on top of it. First, every pair measures (but does not store) the market price at the beginning of each block, before any trades take place. This price is expensive to manipulate because it was set by the last transaction in a previous block.`}</p>
    <p><strong parentName="p">{`To set the measured price to one that is out of sync with the global market price, an attacker has to make a bad trade at the end of a previous block`}</strong>{` , typically with no guarantee that they will be able to arbitrage it back in the next block. Attackers will lose money to arbitrageurs, unless they can “selfishly” mine two blocks in a row. This type of attack presents a number of challenges and `}<a parentName="p" {...{
        "href": "https://arxiv.org/abs/1912.01798"
      }}>{`has not been observed to date`}</a>{`.`}</p>
    <p>{`This alone is not enough. If significant value settles based on the price resulting from this mechanism, then the profit of an attack likely can outweigh the loss.`}</p>
    <p>{`Instead, Starswap V2 adds this end-of-block price to a single cumulative-price variable in the core contract weighted by the amount of time this price existed. `}<strong parentName="p">{`This variable represents a sum of the Starswap price for every second in the entire history of the contract.`}</strong></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/305e79bfff0602175a5d2948fc4a820d/35c87/v2_onchain_price_data.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "85%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAARCAYAAADdRIy+AAAACXBIWXMAACE4AAAhOAFFljFgAAACEElEQVQ4y41UCXLbMAz0/7+Xo50kthwnsu6DlCiewHZIR27sOk00g8EIBJe7BMANAAIAZmb88Ptf6oaIKdvtsd1uue97RKvrGl3Xna1tWwghUlxKeQa9BbwhIqqqCsfjkcuyjB6HwwExFgGKokixCJznefLXgJ+BN6vkGL+Udel/Kn8DZvo4jYkIxljISUFIhWlaYK2Dcx5xLcqODKPs+H+TYcL5AIwcAzOWUkA911jy4ZRMJ3laayxqgTEGxtrbgMZYun94xN3dA++y1yQxKANXSXihwW8j6DCCbfhS8mdLDJ33URZHacY56EpAR4bHARwI8AQmghwEuryCnlQCW2VfAAYiknKCEJKFkPA+oJpHPA1HFFOfkuh0G1CDxJx30OOc7vVmH1rn6On5BbvdnsuqQQiUTjbOwoeAYumQzy1McBcb27bHdpehaVp0/YB+GE8Mr9uGidEpgf1YoJkHBCJ4DslPo0DZVJjUDOsc5lmdC2Q/ipTuMMki4rgptkk9KmSNRDnMqRFTEZmT5OPQoJY9Jr18Idlaevz1G/cPj7x/fUun9NIiKzTqwaIYPN57D+v5urz/FOTMMDaztZYjO+896lEjqzTKXsMHhguMQIx6aJG916j7GcavIN+MXqzeNC/JlNJ/mxeMSSv0coGcDebFfj0p6+itC7HSsX2iv2bw7SyvgOuTeJ3wWdYtu2b4B6ZpMOyp1X06AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v2 onchain price data",
            "title": "v2 onchain price data",
            "src": "/static/305e79bfff0602175a5d2948fc4a820d/c1b63/v2_onchain_price_data.png",
            "srcSet": ["/static/305e79bfff0602175a5d2948fc4a820d/5a46d/v2_onchain_price_data.png 300w", "/static/305e79bfff0602175a5d2948fc4a820d/0a47e/v2_onchain_price_data.png 600w", "/static/305e79bfff0602175a5d2948fc4a820d/c1b63/v2_onchain_price_data.png 1200w", "/static/305e79bfff0602175a5d2948fc4a820d/d61c2/v2_onchain_price_data.png 1800w", "/static/305e79bfff0602175a5d2948fc4a820d/97a96/v2_onchain_price_data.png 2400w", "/static/305e79bfff0602175a5d2948fc4a820d/35c87/v2_onchain_price_data.png 2569w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`This variable can be used by external contracts to track accurate time-weighted average prices (TWAPs) across any time interval.`}</p>
    <p>{`This is done by reading the cumulative price from an ERC20 token pair at the beginning and at the end of the interval. The difference in this cumulative price can then be divided by the length of the interval to create a TWAP for that period.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/f134d0d4763a11354a400c3c944aecad/c6f3b/v2_twap.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "52.66666666666667%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAACE4AAAhOAFFljFgAAABnElEQVQoz5VS2XLbMAzU//+gK8mJW1uWqJsS70PcDqi2SdPkoZwBQYADYHcHBc6T8J8npc9Limt1RVVV6Xa74X6/o21bPJ/PbIyx7LuuQ9M0+b0sy59m5D9aoZSCUirN84x1XSGlzH7f9/zetg2c8xyT11p/2TAjpEsbk2I8/oHvvP8rPo4D1rovKWeEfBPgm0hCaWht4UOA1ga/8tiFRAgB2y6xckKtoIyD9wFCCMzzgmmaiOWJsGkYyvqaphuDuY6w2ubicVoxzTybdQ6MTSirGt+bAXWrIZTD43HH5XJBXddZ/xgjCprknEvRBZD9hh5CBMkQj1MKQumchw8RzkcEyr+jTTUkSRECFaXkiIJUkFJDEn1joZTBLhSkMrkRDdE2wFgPrxxsiLA+wPuY/7OG/UBUXpKQGo+mRUlrVL/ix6PDt7JGVb9gHGcIaTGuGnWn0TYbTMkg+w1GGLRdD9aPp4bq1CzlqeEAYwM6NmBaeB7A+Q5isSsLtlj03IJzDTvsMO0GwzWENplNRvgmAWnw+faf+ff2MXpbm5/eO1QEggLPGwAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v2 twap",
            "title": "v2 twap",
            "src": "/static/f134d0d4763a11354a400c3c944aecad/c1b63/v2_twap.png",
            "srcSet": ["/static/f134d0d4763a11354a400c3c944aecad/5a46d/v2_twap.png 300w", "/static/f134d0d4763a11354a400c3c944aecad/0a47e/v2_twap.png 600w", "/static/f134d0d4763a11354a400c3c944aecad/c1b63/v2_twap.png 1200w", "/static/f134d0d4763a11354a400c3c944aecad/d61c2/v2_twap.png 1800w", "/static/f134d0d4763a11354a400c3c944aecad/97a96/v2_twap.png 2400w", "/static/f134d0d4763a11354a400c3c944aecad/c6f3b/v2_twap.png 2950w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`TWAPs can be used directly or as the basis for moving averages (EMAs and SMAs) as needed.`}</p>
    <p>{`A few notes:`}</p>
    <ul>
      <li parentName="ul">{`For a 10-minute TWAP, sample once every 10 minutes. For a 1-week TWAP, sample once every week.`}</li>
      <li parentName="ul">{`For a simple TWAP, the cost of manipulation increases (approx. linear) with liquidity on Starswap, as well as (approx. linear) with the length of time over which you average.`}</li>
      <li parentName="ul">{`Cost of an attack is relatively simple to estimate. Moving the price 5% on a 1-hour TWAP is approximately equal to the amount lost to arbitrage and fees for moving the price 5% every block for 1 hour.`}</li>
    </ul>
    <p>{`There are some nuances that are good to be aware of when using Starswap V2 as an oracle, especially where manipulation resistance is concerned. The `}<a href='/whitepaper.pdf' target='_blank' rel='noopener noreferrer'>{`whitepaper`}</a>{` elaborates on some of them. Additional oracle-focused developer guides and documentation will be released soon.`}</p>
    <p>{`In the meantime, check out our `}<a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v2-periphery/blob/master/contracts/examples/ExampleOracleSimple.sol"
      }}>{`example implementation`}</a>{` of a 24 hr TWAP Oracle built on Starswap V2!`}</p>
    <h2 {...{
      "id": "flash-swaps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#flash-swaps",
        "aria-label": "flash swaps permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flash Swaps`}</h2>
    <p><strong parentName="p">{`Starswap V2 flash swaps`}</strong>{` allow you to withdraw as much as you want of any ERC20 token on Starswap at no upfront cost and do anything you want with them (execute arbitrary code), provided that by the end of the transaction execution, you either:`}</p>
    <ul>
      <li parentName="ul">{`pay for all ERC20 tokens withdrawn`}</li>
      <li parentName="ul">{`pay for a percentage of ERC20 tokens and return the rest`}</li>
      <li parentName="ul">{`return all ERC20 tokens withdrawn`}</li>
    </ul>
    <p>{`Liquidity provider fees are enforced by subtracting 0.3% from all input amounts, even if the input ERC20 tokens are being returned as part of a flash swap.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/3d2e7aa81f9c82214f1171dae5d411bc/d6bb7/v2_flash_swaps.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "78.99999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAACE4AAAhOAFFljFgAAABiUlEQVQ4y62U626DMAyFef+n659u0qZOa0shIQkEQkiAhDPFUyvWduo2zZLFRfanc4xDhm9iWRb8JbIYIpRQKIoCzjmEEBBjvEB/C87GccLT0zO22y12ux02mw3atr1ReYbfy3VtltR0XQdjDIG01hiG4Uvho7wCBiglIYWAUgqcc9R1/SubaydZeuitwxwCvJ8Q4wLdGjAuIesOkzeIXqK3HrxS9L4SNaWQza3CeZ5RsgrG9MhPJaZppqa39xyMK4TZAXMH50eIBKoUhPwESqVvgWu5Sd1dSw/srqFZWhPGGCXnjNYnvbvGLQ9284tCawf0vUXXGVhryd7gPLwfESIQvUD0NZKBcRzRp3o7wA6Ormn1LgrjstDMTD/QkNMMT2WFl9c9jjn7bDQtetMRIM0uP3EccoaiFFSjlF4BYyR1aztJWVLtnCezJZfUmGqSmv3hiGN+wvv+gGNekIgL8N5JuI60CeemNN9GazSNRl03dH+eOQF/ehLw04/y3U/geh0eHcUby/8VH0+15oTdLl53AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "v2 flash swaps",
            "title": "v2 flash swaps",
            "src": "/static/3d2e7aa81f9c82214f1171dae5d411bc/c1b63/v2_flash_swaps.png",
            "srcSet": ["/static/3d2e7aa81f9c82214f1171dae5d411bc/5a46d/v2_flash_swaps.png 300w", "/static/3d2e7aa81f9c82214f1171dae5d411bc/0a47e/v2_flash_swaps.png 600w", "/static/3d2e7aa81f9c82214f1171dae5d411bc/c1b63/v2_flash_swaps.png 1200w", "/static/3d2e7aa81f9c82214f1171dae5d411bc/d61c2/v2_flash_swaps.png 1800w", "/static/3d2e7aa81f9c82214f1171dae5d411bc/d6bb7/v2_flash_swaps.png 2157w"],
            "sizes": "(max-width: 1200px) 100vw, 1200px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`It is often the case that a series of transactions on Ethereum has a high upfront cost but ultimately a low net cost or is even net profitable by the end of the series. `}<strong parentName="p">{`Flash swaps`}</strong>{` are incredibly useful because they `}<strong parentName="p">{`remove upfront capital requirements and unnecessary constraints on order-of-operations`}</strong>{` for multi-step transactions that use Starswap.`}</p>
    <p>{`One example is `}<strong parentName="p">{`arbitrage with no upfront capital`}</strong>{`. Imagine a scenario where you can sell 200 DAI for 1 ETH on Starswap and then sell that 1 ETH on Oasis for 220 DAI at a 20 DAI profit. But, unfortunately, you don’t have any DAI in your wallet.`}</p>
    <p><img parentName="p" {...{
        "src": "/04736148d9854c4feea27773fe862d24/no_capital.gif",
        "alt": null
      }}></img></p>
    <p>{`With flash swaps you could synchronously withdraw 1 ETH from Starswap, sell it on Oasis for 220 DAI and then pay for the ETH on Starswap with 200 of the DAI you just purchased.`}</p>
    <p>{`Another example use case is `}<strong parentName="p">{`improving the efficiency of margin trading protocols`}</strong>{` that borrow from lending protocols and use Starswap for ERC20 token conversion. This is currently done with the following process:`}</p>
    <ol>
      <li parentName="ol">{`add user ETH to Maker,`}</li>
      <li parentName="ol">{`borrow DAI from Maker`}</li>
      <li parentName="ol">{`swap DAI for ETH on Starswap`}</li>
      <li parentName="ol">{`repeat steps 1–3 `}<strong parentName="li">{`multiple times`}</strong>{` until you reached desired leverage`}</li>
    </ol>
    <p>{`With flash swaps this process is simplified to:`}</p>
    <ol>
      <li parentName="ol">{`withdraw all ETH you want from Starswap`}</li>
      <li parentName="ol">{`add user and Starswap ETH to Maker`}</li>
      <li parentName="ol">{`borrow all DAI you need from Maker`}</li>
      <li parentName="ol">{`return DAI to the Starswap protocol`}</li>
    </ol>
    <p>{`If the Starswap pool does not receive enough DAI to cover the ETH withdrawn, then the entire transaction will revert; thus, all ERC20 tokens are returned or paid for at the end of the transaction.`}</p>
    <h2 {...{
      "id": "corehelper-architecture",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#corehelper-architecture",
        "aria-label": "corehelper architecture permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Core/Helper Architecture`}</h2>
    <p><a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v2-core"
      }}>{`Starswap V2 Core`}</a>{` are the essential Starswap V2 smart contracts, consisting of:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-core/blob/master/contracts/StarswapV2Pair.sol"
        }}>{`StarswapV2Pair.sol`}</a>{`, which implements core swapping and liquidity provision functionality`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-core/blob/master/contracts/StarswapV2Factory.sol"
        }}>{`StarswapV2Factory.sol`}</a>{`, which deploys `}<a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-core/blob/master/contracts/StarswapV2Pair.sol"
        }}>{`StarswapV2Pair.sol`}</a>{` contracts for any ERC20 token/ERC20 token pair`}</li>
    </ul>
    <p><strong parentName="p">{`Core is minimalist in design, removing all logic that is not strictly necessary to secure liquidity stored in its pools.`}</strong>{` Logic related to trader security or ease-of-use must be implemented in external helper contracts. Since external helpers can be improved and replaced without needing to migrate liquidity, `}<strong parentName="p">{`this improves on the flexibility and modularity of Starswap.`}</strong></p>
    <p><a parentName="p" {...{
        "href": "https://github.com/Starswap/Starswap-v2-periphery"
      }}>{`Starswap V2 Periphery`}</a>{` (periphery) is an initial set of helpers, including:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-periphery/blob/master/contracts/StarswapV2Router01.sol"
        }}>{`A router contract`}</a>{` that performs the safety checks needed for safely swapping, adding, and removing liquidity.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-periphery/blob/master/contracts/StarswapV2Migrator.sol"
        }}>{`A migrator contract`}</a>{` that can remove liquidity from Starswap V1 and deposit it into Starswap V2 in a single transaction.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-periphery/blob/master/contracts/libraries/StarswapV2Library.sol"
        }}>{`A library contract`}</a>{` that can be used in the creation of other helper contracts.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-periphery/blob/master/contracts/examples/ExampleOracleSimple.sol"
        }}>{`An example oracle contract`}</a>{` that creates a simple TWAP from Starswap V2 cumulative prices.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/Starswap/Starswap-v2-periphery/blob/master/contracts/examples/ExampleFlashSwap.sol"
        }}>{`An example flash swap contract`}</a>{` that withdraws ERC20 tokens, executes arbitrary code, and then pays for them.`}</li>
    </ul>
    <p><em parentName="p">{`Periphery contracts described as “Example” are for illustrative purposes only and should not be used in actual transactions.`}</em></p>
    <p>{`While this is a huge improvement, there are some new smart contract patterns introduced which developers building on top of Starswap should be aware of.`}</p>
    <ul>
      <li parentName="ul">{`Core uses WETH instead of ETH. Routers can convert between ETH and WETH allowing users to use ETH directly`}</li>
      <li parentName="ul">{`Core stores ERC20 token balances internally instead of relying on the balances stored in the ERC20 token contract`}</li>
      <li parentName="ul">{`Core no longer calls `}<strong parentName="li">{`transferFrom`}</strong>{` on `}<strong parentName="li">{`msg.sender`}</strong>{`. Instead ERC20 tokens should be sent to core directly by a router before calling the `}<strong parentName="li">{`swap`}</strong>{` , `}<strong parentName="li">{`mint`}</strong>{` or `}<strong parentName="li">{`burn`}</strong>{` functions.`}</li>
      <li parentName="ul">{`Core will determine the number of ERC20 tokens sent to it based on the difference between its current and stored balances.`}</li>
      <li parentName="ul">{`Core no longer returns the maximum number of ERC20 tokens for a given input amount. Instead, a router must specify the number of ERC20 tokens it wants. Core will send this number as long as the invariant is preserved after taking 0.3% off any input amount.`}</li>
      <li parentName="ul">{`Routers should handle logic around slippage safety checks and multihop trades.`}</li>
    </ul>
    <p>{`For additional details please read the `}<Link to='/docs/v2/protocol-overview/smart-contracts/' mdxType="Link">{`architecture section`}</Link>{` of the in-progress Starswap V2 docs or the core and periphery smart contracts themselves.`}</p>
    <h2 {...{
      "id": "technical-improvements",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#technical-improvements",
        "aria-label": "technical improvements permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Technical Improvements`}</h2>
    <p>{`Starswap V2 contains many other significant changes and improvements including the following:`}</p>
    <ul>
      <li parentName="ul">{`Smart contracts are written in Solidity instead of Vyper`}</li>
      <li parentName="ul">{`Use CREATE2 to make the pool address deterministic based on the ERC20 token pair`}</li>
      <li parentName="ul">{`Starswap V2 properly handles `}<a parentName="li" {...{
          "href": "https://medium.com/coinmonks/missing-return-value-bug-at-least-130-tokens-affected-d67bf08521ca"
        }}>{`“missing return” ERC20 tokens`}</a>{` that do not work on Starswap V1, such as USDT and OMG`}</li>
      <li parentName="ul">{`Built in metaTransaction “approve” function for liquidity tokens`}</li>
      <li parentName="ul">{`Increase re-entrancy protection using mutex (adds support for ERC777 and other non-standard ERC20 tokens)`}</li>
      <li parentName="ul">{`Fix bug from Starswap V1 that uses all remaining gas on failing transactions`}</li>
      <li parentName="ul">{`More descriptive error messages. The above bug caused failing transactions to only return the error “Bad Jump Destination”`}</li>
    </ul>
    <h2 {...{
      "id": "path-to-sustainability",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#path-to-sustainability",
        "aria-label": "path to sustainability permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Path to Sustainability`}</h2>
    <p>{`Decentralization is in many ways about increasing participation and removing central points of failure. Starswap V1 is already highly decentralized, trustless, and censorship resistant. But for it to achieve its full potential as infrastructure in a fair and open financial system — `}<strong parentName="p">{`it must continue to grow and improve`}</strong>{`.`}</p>
    <p>{`To open a path to self-sustainability, the code for Starswap V2 includes a small protocol charge mechanism. At launch, the protocol charge will default to 0, and the liquidity provider fee will be 0.30%. If the protocol charge is switched on, it will become 0.05% and the liquidity provider fee will be 0.25%.`}</p>
    <p>{`This feature, including the exact percentage amounts, is hardcoded into the core contracts which remain decentralized and non-upgradable. It can be turned on, and directed by, a decentralized governance process deployed after the Starswap V2 launch. There is no expectation that it will be turned on in the near future but it opens the possibility for future exploration.`}</p>
    <p><a parentName="p" {...{
        "href": "/jessewldn/status/1135741055045967874"
      }}>{`/jessewldn/status/1135741055045967874`}</a></p>
    <p>{`In the Classical Period of crypto (2014), `}<a parentName="p" {...{
        "href": "https://blog.ethereum.org/2014/05/06/daos-dacs-das-and-more-an-incomplete-terminology-guide/"
      }}>{`Vitalik described`}</a>{` decentralized autonomous organizations (DAOs) as “automation at the center, humans at the edges.”`}</p>
    <blockquote>
      <p parentName="blockquote">{`an entity that lives on the internet and exists autonomously, but also heavily relies on hiring individuals to perform certain tasks that the automaton itself cannot do`}</p>
    </blockquote>
    <p>{`This perfectly describes the Starswap protocol’s path forward. In Starswap V1 pricing, coordination, listing, and trade execution are fully automated while arbitrage and liquidity provision are incentivized.`}</p>
    <p>{`However, `}<strong parentName="p">{`the best version of Starswap will be one that autonomously incentivizes contributions to its own growth and development`}</strong>{` as well as to the broader ecosystem in which it exists—one that supports the contributions of the `}<strong parentName="p">{`incredible community`}</strong>{` that has formed and continues to grow.`}</p>
    <p>{`Starswap is an ideal candidate for exploring decentralized on-chain cash flows. `}<strong parentName="p">{`Without any additional growth`}</strong>{`, it will generate more than $5M in liquidity provider fees this year. If the protocol charge was on, ~$830,000 of this would instead go to a decentralized funding mechanism used to support contributions to Starswap and its ecosystem.`}</p>
    <p><strong parentName="p">{`This type of support boosts network effects from which Starswap and its users benefit greatly.`}</strong>{` Incentivized contributions lead to increased protocol functionality and usage. Usage generates fees which attracts liquidity. Increased liquidity further entrenches Starswap, attracting additional users, contributors, and integrations.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "900px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/a34d660706547aacdf7dc17465dd7847/8e1fc/graph.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAwAE/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAc2VgAnk/8QAGhAAAwADAQAAAAAAAAAAAAAAAQIDAAQTIv/aAAgBAQABBQI69AAp6M3qVjl6I7Fpkf/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/Aar/xAAVEQEBAAAAAAAAAAAAAAAAAAAQQf/aAAgBAgEBPwGn/8QAGxAAAwADAQEAAAAAAAAAAAAAAAERAiEiQWH/2gAIAQEABj8CXVpMmzWTht/DlGNtnh//xAAbEAEBAAIDAQAAAAAAAAAAAAABEQAhMUFRYf/aAAgBAQABPyFduHvDhBP7mIKzpcm2Xjd4AsAb+uNmsRkDP//aAAwDAQACAAMAAAAQIy//xAAYEQEAAwEAAAAAAAAAAAAAAAABABEhMf/aAAgBAwEBPxBF4R3yf//EABcRAQEBAQAAAAAAAAAAAAAAAAERADH/2gAIAQIBAT8QqcZa3f/EAB4QAQEAAgICAwAAAAAAAAAAAAERACExQVGRYbHR/9oACAEBAAE/EHQXaCKfZ+YW6HmVnmGHJV2W0yMVQVN67vVnvGGaL9mQ5kNwXmm3u/Of/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "graph",
            "title": "graph",
            "src": "/static/a34d660706547aacdf7dc17465dd7847/8e1fc/graph.jpg",
            "srcSet": ["/static/a34d660706547aacdf7dc17465dd7847/f93b5/graph.jpg 300w", "/static/a34d660706547aacdf7dc17465dd7847/b4294/graph.jpg 600w", "/static/a34d660706547aacdf7dc17465dd7847/8e1fc/graph.jpg 900w"],
            "sizes": "(max-width: 900px) 100vw, 900px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`For more details, please refer to the technical `}<a href='/whitepaper.pdf' target='_blank' rel='noopener noreferrer'>{`whitepaper`}</a>{` or code.`}</p>
    <h2 {...{
      "id": "testnet-and-launch-details",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#testnet-and-launch-details",
        "aria-label": "testnet and launch details permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Testnet and Launch Details`}</h2>
    <p>{`While the core smart contracts are finished, additional work is still in progress.`}</p>
    <ul>
      <li parentName="ul">{`Starswap V2 smart contracts are undergoing formal verification and security auditing. Bug bounties will be used to encourage additional community security review.`}</li>
      <li parentName="ul">{`The interface, analytics site, API, and developer SDK are being updated to work with Starswap V2.`}</li>
      <li parentName="ul">{`Documentation, guides, and examples are still being worked on.`}</li>
      <li parentName="ul">{`When everything else is ready, a simple migration UX will be released to improve the experience of moving from Starswap V1 to Starswap V2`}</li>
    </ul>
    <p>{`Pending a successful formal verification, we are optimistic that Starswap V2 can be deployed in Q2 of this year. However, please keep in mind that this is a target and not an announced release date.`}</p>
    <p>{`In the meantime, developers can begin playing with Starswap V2 today! The factory and an initial router smart contract have already been deployed to the Ropsten, Rinkeby, Kovan, and Görli testnets.`}</p>
    <p><strong parentName="p"><Link to='/docs/v2/smart-contracts/factory/#address' mdxType="Link">{`Starswap V2 Factory (Testnet)`}</Link></strong></p>
    <p><strong parentName="p"><Link to='/docs/v2/smart-contracts/router01/#address' mdxType="Link">{`Starswap V2 Router 01 (Testnet)`}</Link></strong></p>
    <p><strong parentName="p">{`We want to hear from you!`}</strong></p>
    <p>{`We will be releasing additional updates, information, and documentation over the coming months. We look forward to continued feedback and involvement from the fantastic Starswap community. To get involved and stay up to date:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://discord.gg/FCfyBSbCU5"
        }}>{`Join the Starswap community discord`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/Starswap"
        }}>{`Follow Starswap on Twitter`}</a></li>
      <li parentName="ul">{`Subscribe to the `}<Link to='/blog/' mdxType="Link">{`Starswap blog`}</Link></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      